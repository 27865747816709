import {
  notebooksDashboardRoute,
  notebookEntriesRoute,
  notebookExperimentsRoute,
  notebooksRoute,
  notebookTemplatesRoute,
} from "../ELN/ELNRouter/ELNRouter";
import { IdTypes } from "../api/GenericTypes";

// These should reflect what the API can actually return
export type ResourceName =
  | "api_keys"
  | "audit_logs"
  | "autoload_clients"
  | "bridges"
  | "data_sources"
  | "data_sources_status"
  | "autoload/is_dataset_known"
  | "autoload/dataset_manual_upload"
  | "autoload/dataset_upload"
  | "samples"
  | "persons"
  | "person_tags"
  | "projects"
  | "project_tags"
  | "organizations"
  | "organization_tags"
  | "announcements"
  | "datasets"
  | "datasets/create"
  | "datasets/update"
  | "documents"
  | "experiments"
  | "methods"
  | "facilities"
  | "equipments"
  | "equipment_tags"
  | "entities"
  | "instruments"
  | "its"
  | "storages"
  | "lab_notebooks"
  | "lab_notebook_experiments"
  | "lab_notebook_entries"
  | "lab_notebook_templates"
  | "parsing/errors"
  | "custom_fields"
  | "parsers"
  | "vendors"
  | "parser_methods"
  | "parser_instruments"
  | "shared_content"
  | "custom_imports"
  | "origins"
  | "users"
  | "folders"
  | "users_preferences"
  | "users_preferences_global"
  | "datasets/zip_size"
  | "formats"
  | "roles"
  | "iconnmr_history_entries"
  | "iconnmr_holders"
  | "iconnmr_status"
  | "inventories"
  | "types";

// Here we collect any other route
export type RouteNames =
  | ResourceName
  | "viewer"
  | "autoload_monitoring"
  | "shared"
  | "table"
  | "manage"
  | "autoload_sources_FH"
  // | "autoload_sources"
  // | "autoload_configurations"
  | "bridges"
  | "data_sources"
  | "pattern_test";

export type EntityRoutes = {
  [key in RouteNames]?: string;
};

export const indexRoutes = {
  about: "about",
  announcements: "announcements",
  apikeys: "apikeys",
  autoload_monitoring: "autoload_monitoring",
  audit_logs: "audit_logs",
  bundles: "bundles",
  bridges: "bridges",
  components: "components",
  custom_fields: "custom_fields",
  custom_imports: "custom_imports",
  dashboard: "",
  datasets: "data",
  // dataset_types: "manage/dataset_types",
  data_sources: "data_sources",
  data_sources_status: "data_sources_status",
  documents: "documents",
  notebooks: `${notebooksRoute}/${notebooksDashboardRoute}`,
  experiments: "experiments",
  facilities: "facilities", // TODO: remove once facilities is migrated
  formats: "formats",
  instruments: "instruments",
  iconnmr: "monitoring/iconnmr",
  inventories: "inventories",
  inventory_items: "inventories/items",
  equipments: "equipments",
  equipment_tags: "equipment_tags",
  methods: "measurement_methods",
  manage: "manage",
  maintenance: "maintenance",
  attachments: "attachments",
  lab_notebooks: notebooksRoute,
  lab_notebook_experiments: `${notebooksRoute}/${notebookExperimentsRoute}`,
  lab_notebook_entries: `${notebooksRoute}/${notebookEntriesRoute}`,
  lab_notebook_templates: `${notebooksRoute}/${notebookTemplatesRoute}`,
  organizations: "organizations",
  organization_tags: "organization_tags",
  persons: "persons",
  person_tags: "person_tags",
  projects: "projects",
  project_tags: "project_tags",
  roles: "roles",
  samples: "samples",
  // sample_types: "manage/sample_types",
  shared: "shared",
  shared_content: "shared_content",
  storage: "storage", // remove once facilties is migrated
  types: "types",
  uuid: "uuid",

  // viewer: getIndexRoute("viewer"),
  // pattern_test: getIndexRoute("pattern_test"),
} as const;

export type RouteKey = keyof typeof indexRoutes;
export const getIndexRoute = (route: RouteKey) => `/${indexRoutes[route]}`;
export const getAddRoute = (route: RouteKey) => `/${indexRoutes[route]}/new`;
export const getBulkEditRoute = (route: RouteKey) => `/${indexRoutes[route]}/edit`;
export const getDetailRoute = (route: RouteKey) => `/${indexRoutes[route]}/:id`;
export const getEditRoute = (route: RouteKey) => `/${indexRoutes[route]}/:id/edit`;
export const getCloneRoute = (route: RouteKey) => `/${indexRoutes[route]}/:id/clone`;
export const getUUIDRoute = (route: RouteKey) => `/${indexRoutes[route]}/:uuid`;

export const getUploadedCsvFilePreviewPage = (route: RouteKey) => `/${indexRoutes[route]}/uploadedCsvFilePreviewPage`;

export const getEditLink = (route: RouteKey, id: IdTypes) => `/${indexRoutes[route]}/${id}/edit`;
export const getCloneLink = (route: RouteKey, id: IdTypes) => `/${indexRoutes[route]}/${id}/clone`;
export const getDetailLink = (route: RouteKey, id: IdTypes) => `/${indexRoutes[route]}/${id}`;
export const getUUIDLink = (uuid: string) => `/#${indexRoutes["uuid"]}/${uuid}`;

// export const mainRoutes = {
//   announcementsAdmin: "/announcements",
//   samples: getIndexRoute("samples"),
//   apikey: "/apikey",
//   persons: getIndexRoute("persons"),
//   organizations: getIndexRoute("organizations"),
//   dashboard: "/",
//   datasets: getIndexRoute("datasets"),
//   viewer: getIndexRoute("viewer"),
//   experiments: getIndexRoute("experiments"),
//   components: "/components",
//   autoload_monitoring: getIndexRoute("autoload_monitoring"),
//   shared: getDetailRoute("shared"),
//   maintenance: "/maintenance",
//   eln: "/eln",
//   about: "/about",
//   manage: getIndexRoute("manage"),
//   pattern_test: getIndexRoute("pattern_test"),
//   roles: getIndexRoute("roles"),
//   projects: "/projects",
// };
